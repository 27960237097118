import {locoScroll} from "./locoScroll";

export function search() {

  // Declare variables

  var input = document.getElementById('searchInput');


  input.addEventListener('keyup', searchKey)

  function searchKey() {
    var filter = input.value,
      ul = document.getElementById('searchResult'),
      lis = ul.getElementsByClassName('search-item'),
      searchTerms = filter.match(/[a-z]+/gi),
      re, index, li, a;
    if (searchTerms) {
      searchTerms = searchTerms.map(function (term) {
        return '(?=.*' + term + ')';
      });

      re = new RegExp(searchTerms.join(''), 'i');
    } else {
      re = /./;
    }

    // Loop through all list items, and hide those who don't match the search query
    for (index = 0; index < lis.length; index++) {
      li = lis[index];
      if (re.test(li.getAttribute('data-keywords'))) {
        if(li.classList.contains('load-more')) {
          li.classList.add('active');
        } else {
          li.style.display = '';
        }
      } else {
        if(li.classList.contains('load-more')) {
          li.classList.remove('active');
        } else {
          li.style.display = 'none';
        }
      }
      if(filter == '') {
        if(li.classList.contains('load-more')) {
          li.classList.remove('active');
        }
      }
    }
    locoScroll.update();
  }
  var buttonLoadMore = document.getElementById('buttonLoadMore');
  buttonLoadMore.addEventListener('click', function (event) {
    var elements = document.getElementsByClassName("search-item");
    var index;
    for (index = 0; index < elements.length; index++) {
      elements[index].classList.remove('load-more');
    }
    buttonLoadMore.style.display = 'none';
    locoScroll.update();
  }, false);
}