import LocomotiveScroll from 'locomotive-scroll';

export const locoScroll = new LocomotiveScroll({
  el: document.querySelector("[data-scroll-container]"),
  smooth: true,
  tablet: {
    smooth: false
  },
  smartphone: {
    smooth: false
  },
});