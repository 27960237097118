
//window.jQuery = window.$ = jQuery;
//STYLE
import style from './styles/index.scss'

//custom scripts

// You can also use <link> for styles
// ..
import {locoScroll} from "./scripts/locoScroll";

document.addEventListener("DOMContentLoaded", function (event) {
  window.onload = function () {
    locoScroll.update();
    async function load() {

      let search =  await import('./scripts/search');
      search.search();

    }
    load();
    new ResizeObserver(() =>
       locoScroll.update()).observe(document.querySelector("[data-scroll-container]"))
  }
});

